import React, { Fragment } from "react";

import { Overheader, Header, Separator, GlobalStyles, About, Footer } from "../components";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <Fragment>
      <GlobalStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Organic Audio</title>
        <link rel="icon" href="assets/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Lora&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Damion&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@300;500&display=swap" rel="stylesheet" />
      </Helmet>
      <Overheader />
      <Separator />
      <Header />
      <Separator />
      <About />
      <Separator />
      <Footer />
    </Fragment>
  );
};

export default ContactPage;
